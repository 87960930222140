import React from 'react';

import {
  Layout,
  HeroGeneric,
  ContactFormBanner,
  TrainingBanner,
  CourseGrid,
  SEO,
} from '../components';

export default function Training(props) {
  return (
    <Layout {...props}>
      <SEO title="Training" pathname={props.location.pathname} />
      <HeroGeneric title="Training" />
      <TrainingBanner />
      <CourseGrid />
      <ContactFormBanner />
    </Layout>
  );
}
